import parseDecimalNumber from './parseDecimalNumber';

let form = document.querySelector('.loanCartNo');

if (form) {
    
    let submitBtn = form.querySelector('button');
    let numberInputs = document.querySelectorAll('input[data-custom-type="number"]');

    console.log(numberInputs)

    if (numberInputs.length > 0) {
        for (let i = 0; i < numberInputs.length; i++) {

            numberInputs[i].addEventListener('keyup', function () {
                let thisValue = this.value.replace('.', '');
                let formattedNumber = parseDecimalNumber(thisValue, '.,');

                let localeNumber = formattedNumber.toLocaleString('da-DK', { useGrouping: true });

                if (localeNumber !== "NaN") {
                    this.value = localeNumber;
                }
            });
        }

        submitBtn.addEventListener('click', function (e) {
           
            for (let i = 0; i < numberInputs.length; i++) {
                let numberValue = numberInputs[i].value;
                let formattedNumber = numberValue.replaceAll('.', '');

                numberInputs[i].value = formattedNumber;
            }
        });
    }

}


